// import Bootstrap
import 'bootstrap';

// Navbar menu toggle active classes
document.querySelector('.navbar-toggler').addEventListener('click', () => {
    document.querySelector('.navbar-toggler').classList.toggle('active-item');
    document.querySelectorAll('[id^=nav-toggler-]').forEach((el) => {
        const style = window.getComputedStyle(el);
        if (style.display !== 'none') el.classList.toggle('active-item');
    });
    document.querySelectorAll('.nav-submenu').forEach((element) => {
        element.classList.remove('active-item');
    });
    document.querySelectorAll('.popup-overlay').forEach((element) => {
        element.classList.remove('active-item');
    });
    document.querySelector('a[data-title=services] i').classList.remove('bi-chevron-up');
    document.body.style.overflowY = document.querySelector('#nav-toggler-mobile').classList.contains('active-item')
        ? 'hidden'
        : 'auto';
});

// On close mobile overlay remove active classes
document.querySelector('.mobile-overlay').addEventListener('click', () => {
    document.querySelector('.navbar-toggler').classList.remove('active-item');
    document.querySelectorAll('[id^=nav-toggler-]').forEach((element) => {
        element.classList.remove('active-item');
    });
    document.querySelectorAll('.nav-submenu').forEach((element) => {
        element.classList.remove('active-item');
    });
    document.querySelector('.popup-overlay').classList.remove('active-item');
    document.body.style.overflowY = 'auto';
});

// On resize remove menu active classes
window.addEventListener('resize', () => {
    document.querySelector('.navbar-toggler').classList.remove('active-item');
    document.querySelectorAll('[id^=nav-toggler-]').forEach((element) => {
        element.classList.remove('active-item');
    });
    document.querySelectorAll('.nav-submenu').forEach((element) => {
        element.classList.remove('active-item');
    });
    document.querySelector('.popup-overlay').classList.remove('active-item');
    document.body.style.overflowY = 'auto';
});

// Services menu on hover dropdown
document.querySelector('#nav-toggler-desktop a[data-title=services]').addEventListener('mouseover', () => {
    document.querySelector('#nav-toggler-desktop .nav-submenu').classList.add('active-item');
    document.querySelector('.popup-overlay').classList.add('active-item');
    document.querySelector('#nav-toggler-desktop a[data-title=services] i').classList.add('bi-chevron-up');
});

// Drop menu overlay on hover dropdown up
document.querySelector('.popup-overlay').addEventListener('mouseover', () => {
    document.querySelector('#nav-toggler-desktop .nav-submenu').classList.remove('active-item');
    document.querySelector('.popup-overlay').classList.remove('active-item');
    document.querySelector('#nav-toggler-desktop a[data-title=services] i').classList.remove('bi-chevron-up');
});

// Mobile services menu toggler
document.querySelector('#nav-toggler-mobile a[data-title=services]').addEventListener('click', (e) => {
    document.querySelector('#nav-toggler-mobile .nav-submenu').classList.toggle('active-item');
    e.target.querySelector('i').classList.toggle('bi-chevron-up');
});

// Resize header on load, resize
function scrollActions() {
    const scrollPos = window.scrollY;
    const headerHeight = document.querySelector('.header-area').offsetHeight;
    const mainPos = Math.round(document.querySelector('.main-offset').offsetTop) - headerHeight;

    document.querySelector('.header-area').classList.toggle('fixed-header', scrollPos > mainPos);
    document.querySelector('#back-to-top').classList.toggle('active-item', scrollPos > mainPos);
}
window.addEventListener('load', scrollActions);
window.addEventListener('scroll', scrollActions);

// Tech stack tab content min height on load, resize
function techStackHeight() {
    let contentHeights = [];
    let contHeight;
    document.querySelectorAll('.technology-area .tab-content .tab-pane').forEach((el) => {
        el.style.display = 'block';
        setTimeout(() => {
            el.style.cssText = '';
        }, 0);
        contentHeights.push(Math.round(el.offsetHeight));
        contHeight = Math.max(...contentHeights);
    });
    document.querySelector('.technology-area .tab-content').style.minHeight = `calc(3rem + ${contHeight}px)`;
}
if (document.querySelectorAll('.technology-area').length) {
    window.addEventListener('load', techStackHeight);
    window.addEventListener('resize', techStackHeight);
}

// Preloader
window.addEventListener('load', () => {
    document.querySelector('.preloader').classList.add('preloader-out');
    setTimeout(() => {
        document.querySelector('.preloader').style.display = 'none';
    }, 400);
});

// Back to top button
if (document.querySelectorAll('.back-to-top').length) {
    document.querySelector('.back-to-top').addEventListener('click', () => {
        document.querySelector('html, body').scroll({
            top: 0,
            behavior: 'smooth',
        });
    });
}

// Adding active class on menu next page load
let breakPoint = window.innerWidth;
const mainTitle = document.querySelector('main').getAttribute('class');

if (!mainTitle.includes('index') && breakPoint > 992) {
    document.querySelector('.navbar-toggler').classList.add('active-item');
    document.querySelectorAll('[id^=nav-toggler-]').forEach((el) => {
        const style = window.getComputedStyle(el);
        if (style.display !== 'none') el.classList.toggle('active-item');
    });
}

// Adding active class on navigation menu items
document.querySelectorAll('[id^=nav-toggler-] a.nav-title').forEach((el, i) => {
    const itemTitle = el.getAttribute('data-title');
    if (mainTitle.includes(itemTitle)) el.classList.add('active-item');
});

// Custom upload file form
const input = document.querySelector('#cvFile');
const fileName = document.querySelector('#fileName');
const removeFileBtn = document.querySelector('#removeFileBtn');
if (input) {
    input.addEventListener('change', function () {
        fileName.textContent = input.files[0].name;
        removeFileBtn.style.display = 'block';
    });
    removeFileBtn.addEventListener('click', function () {
        input.value = '';
        fileName.textContent = 'No CV uploaded';
        removeFileBtn.style.display = 'none';
    });
}
